import React, { useEffect, useState } from 'react'
import { Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, Image } from '@chakra-ui/react'
import { useInitialAnimation } from '../../contexts/InitialAnimationContext'

const InitialAnimation = () => {
  const { introSrc, isAnimationOpen, onAnimationClose } = useInitialAnimation()
  const [vh, setVH] = useState<number>(0)

  const handleUpdateVh = () => {
    setVH(window.innerHeight)
  }

  useEffect(() => {
    handleUpdateVh()
    window.addEventListener('resize', handleUpdateVh)
    return () => window.removeEventListener('resize', handleUpdateVh)
  }, [])

  return (
    <Drawer isOpen={isAnimationOpen} onClose={onAnimationClose} placement='top' isFullHeight>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody p={0}>
          <Flex
            w='100%'
            h={vh}
            alignItems='center'
            justifyContent='center'
            background='green.300'
            zIndex={9999}
          >
            {introSrc ? (
              <Image
                src={introSrc}
                alt='Site intro gif'
                loading='lazy'
                w={{ base: '12rem', sm: '2xs', md: 'xs', lg: '23vw' }}
                align='center'
                margin='auto'
                _focus={{ outline: 'none', border: 'none' }}
                cursor='pointer'
                onClick={() => onAnimationClose()}
              />
            ) : null}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default InitialAnimation
