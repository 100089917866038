import { Resume } from '../types/Resume'

const mockResume: Resume = {
  email: 'paulo_eduardo@mac.com',
  instagram: 'https://www.linkedin.com/in/pbarbosa19/',
  linkedin: 'https://www.linkedin.com/in/pbarbosa19/',
  curriculum: '#',
  about:
    'I AM PROFESSIONAL CREATIVE WITH ALMOST 20 YEARS OF EXPERIENCE, 7 OF WHICH WERE IN THE US. STRONG CREATIVE AND ANALYTICAL SKILLS. TEAM PLAYER WITH AN EYE FOR DETAIL.',
  experiences: ['FREELANCER', 'BIO2', 'PUBLICS', 'VMLY&R', 'LEO BURNETT', 'TEAM ONE', '-']
}

export default mockResume