import { Stack, Flex, HStack, Link, Center, Icon, useBreakpointValue } from '@chakra-ui/react'

import { FaInstagramSquare, FaLinkedin } from 'react-icons/fa'
import { CurriculumIcon } from '../Icons/Curriculum'

interface BioContactsProps {
  instagram?: string
  linkedin?: string
  email?: string
  curriculum?: string
}

const BioContacts = ({
  instagram = '',
  linkedin = '',
  email = 'PAULO_EDUARDO@MAC.COM',
  curriculum = ''
}: BioContactsProps) => {
  const isSmallScreen = useBreakpointValue({
    base: true,
    md: false
  })

  return (
    <>
      <Stack
        direction='row'
        align='center'
        justify='center'
        width='100%'
        h='fit-content'
        borderBottom='1px'
        borderColor='gray.700'
        paddingBottom={{ base: '3', md: '4' }}
        px='4'
      >
        <Flex direction='row'>
          <HStack
            spacing={{
              base: '7',
              md: '3'
            }}
            paddingRight='4'
            align='center'
            justify='center'
          >
            <Link href={linkedin} isExternal>
              <Center>
                <Icon
                  fontSize={{
                    base: '5xl',
                    md: '4xl',
                    '6xl': '7xl'
                  }}
                  as={FaLinkedin}
                />
              </Center>
            </Link>
            <Link href={instagram} isExternal>
              <Center>
                <Icon
                  fontSize={{
                    base: '5xl',
                    md: '4xl',
                    '6xl': '7xl'
                  }}
                  as={FaInstagramSquare}
                />
              </Center>
            </Link>
          </HStack>
          <Flex
            px='4'
            borderRight={!isSmallScreen ? '1px' : '0'}
            borderLeft='1px'
            borderColor='gray.700'
          >
            <Link href={curriculum} isExternal>
              <Center>
                <CurriculumIcon
                  fontSize={{
                    base: '5xl',
                    md: '4xl',
                    '6xl': '7xl'
                  }}
                />
              </Center>
            </Link>
          </Flex>
        </Flex>
        {!isSmallScreen && (
          <Link
            fontStyle='italic'
            lineHeight='200%'
            textTransform='uppercase'
            fontWeight={500}
            fontSize={{base: 'sm', '6xl': '2xl'}}
            href={`mailto:${email}?subject=Contato`}
          >
            {email}
          </Link>
        )}
      </Stack>
      {isSmallScreen && (
        <Flex
          width='100%'
          align='center'
          justify='center'
          borderBottom='1px'
          borderColor='gray.700'
          paddingBottom={{ base: '3', md: '4' }}
        >
          <Link
            fontStyle='italic'
            lineHeight='200%'
            textTransform='uppercase'
            fontWeight={500}
            fontSize='xs'
            href={`mailto:${email}?subject=Contato`}
          >
            {email}
          </Link>
        </Flex>
      )}
    </>
  )
}

export default BioContacts
