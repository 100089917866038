import { Flex } from '@chakra-ui/react'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import JobContent from '../../components/JobContent'
import JobFooter from '../../components/JobFooter'

import { useJobs } from '../../contexts/JobsContext'

const JobPage: React.FC = () => {
  const { jobTitle } = useParams()
  const { jobs } = useJobs()
  const navigate = useNavigate()

  const job = jobs.find((job) => job.title.toLowerCase() === jobTitle!.toLowerCase())

  if (!job) {
    navigate('/')
    return null
  }

  const previousJob = () => {
    const currentIndex = jobs.indexOf(job)
    const previousIndex = currentIndex === 0 ? jobs.length - 1 : currentIndex - 1
    const previousJobSlug = jobs[previousIndex].title.toLowerCase()

    navigate(`/${previousJobSlug}`)
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const nextJob = () => {
    const currentIndex = jobs.indexOf(job)
    const nextIndex = currentIndex === jobs.length - 1 ? 0 : currentIndex + 1
    const nextJobSlug = jobs[nextIndex].title.toLowerCase()

    navigate(`/${nextJobSlug}`)
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  return (
    <Flex width='100%' flex={1} align='center' justifyContent='center' direction='column'>
      <JobContent job={job} key={job.id} />
      <JobFooter
        onLeftClick={previousJob}
        onCenterClick={() => navigate('/')}
        onRigthClick={nextJob}
      />
    </Flex>
  )
}

export default JobPage
