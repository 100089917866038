import { Flex } from '@chakra-ui/react'
import React from 'react'
import InitialAnimation from '../../components/InitialAnimation'
import JobSection from '../../components/JobSection'
import { useJobs } from '../../contexts/JobsContext'

const Home: React.FC = () => {
  const { jobs } = useJobs()

  return (
    <Flex
      width='100%'
      height='100%'
      align='center'
      justifyContent='center'
      direction='column'
      rowGap='1px'
      backgroundColor='green.300'
    >
      <InitialAnimation />
      {jobs.map((job) => (
        <JobSection job={job} key={job.id} />
      ))}
    </Flex>
  )
}

export default Home
