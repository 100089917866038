import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { mockJobs } from '../mock/MockJobs'
import api from '../services/api'
import { Job } from '../types/Job'
import { MediaResource } from '../types/MediaResource'

interface JobsContextProps {
  children: ReactNode
}

interface JobsContextData {
  jobs: Job[]
}

const JobsContext = createContext({} as JobsContextData)

export function JobsContextProvider({ children }: JobsContextProps) {
  const [jobs, setJobs] = useState<Job[]>([] as Job[])

  useEffect(() => {
    if (process.env.REACT_APP_STAGE === 'mock') {
      setJobs(mockJobs)
    } else {
      api.get('/job').then(({ data }) => {
        const newJobs: Job[] = data.map((job: any) => {
          const resources = job.job_resources
            ? job.job_resources.map((resource: any) => {
                return {
                  video: {
                    title: resource.resource_video_title,
                    url: resource.resource_video_url,
                    thumbnail: resource.resource_thumbnail
                  },
                  image: {
                    title: resource.resource_image_title,
                    url: resource.resource_file
                  }
                }
              })
            : ([] as MediaResource[])

          return {
            id: job.id,
            title: job.job_title,
            subtitle: job.job_subtitle,
            description: job.job_description,
            date: job.job_date,
            thumbnail: job.job_thumbnail.guid,
            resources
          }
        })

        try {
          api
            .get('/resume')
            .then(({ data }) => {
              const jobsOrder: number[] = data[0].jobs_order.map((item: any) => item.ID)

              setJobs(
                newJobs.sort((a, b) => {
                  const orderA = jobsOrder.indexOf(Number(a.id))
                  const orderB = jobsOrder.indexOf(Number(b.id))

                  if (orderB < 0) return -1
                  if (orderA < 0) return 1

                  return orderA - orderB
                })
              )
            })
            .catch(() => setJobs(newJobs))
        } catch {
          setJobs(newJobs)
        }
      })
    }
  }, [])

  return <JobsContext.Provider value={{ jobs }}>{children}</JobsContext.Provider>
}

export const useJobs = () => useContext(JobsContext)
