import { Flex, Text } from '@chakra-ui/react'

interface BioResumeProps {
  resume?: string
}

const BioResume = ({
  resume = 'I am professional creative with almost 20 years of experience, 7 of which were in the US. Strong creative and analytical skills. Team player with an eye for detail.'
}: BioResumeProps) => {
  return (
    <Flex
      w='100%'
      h='fit-content'
      paddingLeft='7'
      paddingBottom={{ base: '3', md: '4' }}
      fontSize={{ base: 'sm', '6xl': '2xl' }}
      lineHeight='183%'
      fontStyle='italic'
      fontWeight={400}
      direction='column'
      borderBottom='1px'
      borderColor='gray.700'
    >
      <Text casing='uppercase'>{resume}</Text>
    </Flex>
  )
}

export default BioResume
