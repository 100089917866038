import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/react'

import React from 'react'
import { useMenuDrawer } from '../../contexts/MenuDrawerContext'
import Bio from '../Bio'
import Header from '../Header'

const MenuDrawer = () => {
  const { isOpen, onClose, isBioDrawerOpen } = useMenuDrawer()

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement='top' size='full'>
      <DrawerOverlay />
      <DrawerContent background='green.300'>
        <DrawerHeader p='0' borderBottom='1px' borderColor='gray.700'>
          <Header />
        </DrawerHeader>
        <DrawerBody p={0}>{isBioDrawerOpen && <Bio />}</DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default MenuDrawer
