import * as React from 'react'

import { ChakraProvider, Flex } from '@chakra-ui/react'

import { theme } from './styles/theme'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Header from './components/Header'
import { MenuDrawerProvider } from './contexts/MenuDrawerContext'
import MenuDrawer from './components/MenuDrawer'
import StickyHeader from './components/StickyHeader'
import JobPage from './pages/JobPage'

import { JobsContextProvider } from './contexts/JobsContext'
import { ResumeContextProvider } from './contexts/ResumeContext'
import { InitialAnimationProvider } from './contexts/InitialAnimationContext'

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <ResumeContextProvider>
          <JobsContextProvider>
            <InitialAnimationProvider>
              <MenuDrawerProvider>
                <Flex w='100%' minH='100vh' direction='column'>
                  <StickyHeader>
                    <Header />
                  </StickyHeader>
                  <MenuDrawer />
                  <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/:jobTitle' element={<JobPage />} />
                  </Routes>
                </Flex>
              </MenuDrawerProvider>
            </InitialAnimationProvider>
          </JobsContextProvider>
        </ResumeContextProvider>
      </BrowserRouter>
    </ChakraProvider>
  )
}
