import { useDisclosure } from '@chakra-ui/react'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import api from '../services/api'

interface InitialAnimationContextData {
  introSrc?: string
  isAnimationOpen: boolean
  onAnimationOpen: () => void
  onAnimationClose: () => void
}

const InitialAnimationContext = createContext({} as InitialAnimationContextData)

interface InitialAnimationProviderProps {
  children: ReactNode
}

const getAnimationData = (response: any): string => {
  const data = response[0]

  const initialAnimation = data.initial_animation

  if (!initialAnimation) return '/images/intro.gif'

  return initialAnimation.guid
}

export function InitialAnimationProvider({ children }: InitialAnimationProviderProps) {
  const [introSrc, setIntroSrc] = useState<string>()

  const {
    isOpen: isAnimationOpen,
    onOpen: onAnimationOpen,
    onClose: onAnimationClose
  } = useDisclosure({
    defaultIsOpen: true
  })

  useEffect(() => {
    if (process.env.REACT_APP_STAGE === 'mock') {
      setIntroSrc('/images/intro.gif')
    } else {
      try {
        api
          .get('/resume')
          .then(({ data }) => setIntroSrc(getAnimationData(data)))
          .catch(() => setIntroSrc('/images/intro.gif'))
      } catch (e) {
        console.error(`Error: ${e}`)
      }
    }
  }, [])

  return (
    <InitialAnimationContext.Provider
      value={{ introSrc, isAnimationOpen, onAnimationOpen, onAnimationClose }}
    >
      {children}
    </InitialAnimationContext.Provider>
  )
}

export const useInitialAnimation = () => useContext(InitialAnimationContext)
