import { Flex, Image } from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import dotsPatternBackground from '../../assets/images/reticula.png'

interface VideoPlayerProps {
  url: string
  title?: string
  thumbnailUrl?: string
}

const VideoPlayer = ({ url, thumbnailUrl, title = 'video' }: VideoPlayerProps) => {
  const playerRef = useRef() as React.MutableRefObject<ReactPlayer>
  const viewRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const previewRef = useRef() as React.MutableRefObject<HTMLImageElement>

  const [preview, setPreview] = useState(true)

  return (
    <ReactPlayer
      url={url}
      ref={playerRef}
      width='100%'
      height='100%'
      controls
      playing
      playsinline
      config={{
        vimeo: {
          title: title,
          playerOptions: {
            responsive: true,
            width: '100%',
            height: '100%',
            autoplay: true
          }
        }
      }}
      wrapper={({ children }) => {
        if (preview) {
          return (
            <Flex
              ref={viewRef}
              width='100%'
              height='100%'
              align='center'
              justify='center'
              position='relative'
              _after={{
                content: `""`,
                position: 'absolute',
                w: '100%',
                h: '100%',
                bottom: '0',
                left: '0',
                zIndex: 12,
                opacity: 0.9,
                backgroundImage: dotsPatternBackground,
                backgroundSize: {
                  base: '0.12rem 0.12rem',
                  sm: '0.2rem 0.2rem',
                  md: 'initial',
                  lg: 'initial',
                  '2xl': 'initial',
                  '3xl': 'initial'
                },
                'pointer-events': 'none'
              }}
            >
              <Image
                src={thumbnailUrl}
                alt={title}
                loading='lazy'
                width='100vw'
                overflow='hidden'
                cursor='pointer'
                onClick={() => setPreview(false)}
              />
            </Flex>
          )
        }

        return (
          <Flex
            ref={viewRef}
            width='100%'
            height='100%'
            align='center'
            justify='center'
            direction='column'
            position='relative'
            _after={{
              content: `""`,
              position: 'absolute',
              w: '100%',
              h: '100%',
              bottom: '0',
              left: '0',
              zIndex: 12,
              opacity: 0.9,
              backgroundImage: dotsPatternBackground,
              backgroundSize: {
                base: '0.12rem 0.12rem',
                sm: '0.2rem 0.2rem',
                md: 'initial',
                lg: 'initial',
                '2xl': 'initial',
                '3xl': 'initial'
              },
              'pointer-events': 'none'
            }}
          >
            <Image
              src={thumbnailUrl}
              alt={title}
              ref={previewRef}
              loading='lazy'
              width='100vw'
              overflow='hidden'
              cursor='progress'
            />
            {children}
          </Flex>
        )
      }}
      onReady={() => {
        viewRef.current.scrollIntoView({ behavior: 'smooth' })
        previewRef.current.remove()
      }}
    />
  )
}

export default VideoPlayer
