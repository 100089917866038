import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import api from '../services/api'
import mockResume from '../mock/MockResume'

import { Resume } from '../types/Resume'

interface ResumeContextProps {
  children: ReactNode
}

type ResumeContextData = Resume

const ResumeContext = createContext({} as ResumeContextData)

const getResumeByResponse = (response: any): Resume => {
  const data = response[0]

  const resume: Resume = {
    email: data.resume_email,
    instagram: data.resume_instagram_url,
    linkedin: data.resume_linkedin_url,
    curriculum: data.resume_curriculum_file.guid ?? '',
    about: data.resume_about,
    experiences: data.resume_experience
  }

  return resume
}

export function ResumeContextProvider({ children }: ResumeContextProps) {
  const [resumeData, setResumeData] = useState<Resume>({} as Resume)

  useEffect(() => {
    if (process.env.REACT_APP_STAGE === 'mock') {
      setResumeData(mockResume)
    } else {
      try {
        api.get('/resume').then(({ data }) => setResumeData(getResumeByResponse(data)))
      } catch (e) {
        console.error(`Error: ${e}`)
      }
    }
  }, [])

  return <ResumeContext.Provider value={resumeData}>{children}</ResumeContext.Provider>
}

export const useResume = () => useContext(ResumeContext)
