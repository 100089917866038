import { Job } from '../types/Job'

const mockJobs: Job[] = [
  {
    id: '96',
    title: 'biO2',
    subtitle: 'REEL',
    description: 'O trabalho foi feito com o objetivo de testar a aplicação.',
    date: '2022-08-01',
    thumbnail: '/video/bio2_final_1920x1080.mp4',
    resources: [
      {
        video: {
          title: '',
          url: 'https://player.vimeo.com/video/399421333',
          thumbnail: '/images/biO2_play-1.jpg'
        }
      },
      {
        image: {
          title: 'GIF BIO2',
          url: ['/images/biO2_motion.gif']
        }
      },
      {
        image: {
          title: '',
          url: [
            '/images/Heart-1_1400x900-high_2.jpg',
            '/images/Heart-1_1400x900-high_2.jpg',
            '/images/Heart-1_1400x900-high_2.jpg',
            '/images/Heart-1_1400x900-high_2.jpg'
          ]
        }
      },
      {
        video: {
          title: '',
          url: 'https://player.vimeo.com/video/399421333',
          thumbnail: '/images/biO2_play-1.jpg'
        }
      },
      {
        image: {
          title: 'GIF BIO2',
          url: ['/images/biO2_motion.gif']
        }
      },
      {
        image: {
          title: '',
          url: [
            '/images/Heart-1_1400x900-high_2.jpg',
            '/images/Heart-1_1400x900-high_2.jpg',
            '/images/Heart-1_1400x900-high_2.jpg',
            '/images/Heart-1_1400x900-high_2.jpg'
          ]
        }
      },
      {
        video: {
          title: '',
          url: 'https://player.vimeo.com/video/399421333',
          thumbnail: '/images/biO2_play-1.jpg'
        }
      },
      {
        image: {
          title: 'GIF BIO2',
          url: ['/images/biO2_motion.gif']
        }
      },
      {
        image: {
          title: '',
          url: [
            '/images/Heart-1_1400x900-high_2.jpg',
            '/images/Heart-1_1400x900-high_2.jpg',
            '/images/Heart-1_1400x900-high_2.jpg',
            '/images/Heart-1_1400x900-high_2.jpg'
          ]
        }
      }
    ]
  },
  {
    id: '162',
    title: 'ROYALS',
    subtitle: 'RELAY THE WAY',
    description: 'O trabalho foi feito com o objetivo de testar a aplicação.',
    date: '2022-09-01',
    thumbnail: '/video/Royals.mp4',
    resources: [
      {
        image: {
          title: 'Royals foto',
          url: ['/images/BCKwifi_intro-3.jpg']
        }
      },
      {
        image: {
          title: 'Royals foto',
          url: ['/images/BCKwifi_intro-3.jpg']
        }
      },
      {
        image: {
          title: 'Royals foto',
          url: ['/images/BCKwifi_intro-3.jpg']
        }
      },
      {
        image: {
          title: 'Royals foto',
          url: ['/images/BCKwifi_intro-3.jpg']
        }
      },
      {
        image: {
          title: 'Royals foto',
          url: ['/images/BCKwifi_intro-3.jpg']
        }
      },
      {
        image: {
          title: 'Royals foto',
          url: ['/images/BCKwifi_intro-3.jpg']
        }
      }
    ]
  },
  {
    id: '109',
    title: 'CARREFOUR',
    subtitle: 'Unicos',
    description: 'Trabalho realizado no Carrefour para hortaliças.',
    date: '2022-08-23',
    thumbnail: '/video/CRF.mp4',
    resources: [
      {
        image: {
          title: '',
          url: ['/images/CRF_unicos-1600x900_low.jpg']
        }
      },
      {
        video: {
          title: '',
          url: 'https://player.vimeo.com/video/309873034',
          thumbnail: '/images/CRF_unicos-video.jpg'
        }
      },
      {
        image: {
          title: '',
          url: ['/images/CRF_unicos-1600x900_low.jpg']
        }
      },
      {
        video: {
          title: '',
          url: 'https://player.vimeo.com/video/309873034',
          thumbnail: '/images/CRF_unicos-video.jpg'
        }
      },
      {
        image: {
          title: '',
          url: ['/images/CRF_unicos-1600x900_low.jpg']
        }
      },
      {
        video: {
          title: '',
          url: 'https://player.vimeo.com/video/309873034',
          thumbnail: '/images/CRF_unicos-video.jpg'
        }
      }
    ]
  },
  {
    id: '142',
    title: 'ALCHEMY GOODS',
    subtitle: 'DONORS',
    description: 'O trabalho foi feito com o objetivo de testar a aplicação.',
    date: '2022-09-01',
    thumbnail: '/video/AG_donors.mp4',
    resources: [
      {
        image: {
          title: 'AG 7',
          url: ['/images/AG_7.jpg']
        }
      },
      {
        image: {
          title: 'AG 23',
          url: ['/images/AG_23.jpg']
        }
      },
      {
        image: {
          title: 'AG 31',
          url: ['/images/AG_31.jpg']
        }
      },
      
    ]
  },
]

export { mockJobs }
