import { Button, Flex, Heading, Image, LinkBox, LinkOverlay } from '@chakra-ui/react'
import React from 'react'

import { Link } from 'react-router-dom'

import Logo from '../../assets/logo.svg'
import { useMenuDrawer } from '../../contexts/MenuDrawerContext'

const Header = () => {
  const { isOpen, onOpenBioDrawer, onClose } = useMenuDrawer()

  return (
    <Flex
      as='header'
      w='100%'
      h={{ base: '14', '6xl': '20' }}
      marginX='auto'
      px={{ base: 3, sm: 4, md: 6, lg: 7 }}
      align='center'
      background='green.300'
      position='relative'
      direction='row-reverse'
      overflow='visible'
    >
      <Flex
        align='center'
        justify='center'
        margin='auto'
        position='absolute'
        left={0}
        right={0}
        w='100%'
        zIndex={0}
      >
        <LinkBox w='fit-content' h='fit-content' _focus={{ outline: 'none', border: 'none' }}>
          <LinkOverlay
            as={Link}
            to='/'
            w='fit-content'
            h='fit-content'
            _focus={{ outline: 'none', border: 'none' }}
          >
            <Image
              src={Logo}
              alt='Site logo'
              loading='lazy'
              h={{ base: '8', sm: '8', md: '8', lg: '10', '6xl': '12' }}
              align='center'
              margin='auto'
              _focus={{ outline: 'none', border: 'none' }}
            />
          </LinkOverlay>
        </LinkBox>
      </Flex>
      <Flex
        align='end'
        zIndex={1}
        justifyContent='center'
        alignItems='center'
        borderBottom='1px'
        borderColor='gray.700'
      >
        <Button
          variant='unstyled'
          width='fit-content'
          minW='fit-content'
          height='100%'
          aria-label='Close about page'
          onClick={() => (isOpen ? onClose() : onOpenBioDrawer())}
        >
          <Heading
            color='gray.700'
            fontStyle='italic'
            fontSize={{ base: '1rem', '6xl': '1.5rem' }}
            m='0'
            px='2px'
            lineHeight={{ base: '90%', sm: '90%', md: 'initial' }}
            overflow='visible'
          >
            {isOpen ? 'BACK' : 'BIO'}
          </Heading>
        </Button>
      </Flex>
    </Flex>
  )
}

export default Header
