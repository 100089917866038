import React from 'react'

import { AspectRatio, Flex, Heading, LinkBox, LinkOverlay, Text } from '@chakra-ui/react'
import ReactPlayer from 'react-player'

import { Job } from '../../types/Job'
import dotsPatternBackground from '../../assets/images/reticula.png'
import { Link } from 'react-router-dom'
interface JobSectionProps {
  job: Job
}

const JobSection: React.FC<JobSectionProps> = ({ job }: JobSectionProps) => {
  const { title, subtitle, thumbnail } = job

  return (
    <LinkBox as='div' width='100%' height='100%'>
      <ReactPlayer
        url={thumbnail ?? undefined}
        height='100%'
        loop
        muted
        playing
        playsinline
        wrapper={({ children }) => {
          return (
            <AspectRatio ratio={{ base: 390 / 135, sm: 390 / 135, md: 1920 / 510 }}>
              <Flex
                width='100%'
                height='100%'
                align={'center'}
                justify='center'
                direction={'column'}
                backgroundColor='gray.700'
                position='relative'
                _after={{
                  content: `""`,
                  position: 'absolute',
                  w: '100%',
                  h: '100%',
                  bottom: '0',
                  left: '0',
                  zIndex: 12,
                  opacity: 0.9,
                  backgroundImage: dotsPatternBackground,
                  backgroundSize: {
                    base: '0.12rem 0.12rem',
                    sm: '0.2rem 0.2rem',
                    md: 'initial',
                    lg: 'initial',
                    '2xl': 'initial',
                    '3xl': 'initial'
                  },
                  'pointer-events': 'none'
                }}
                sx={{
                  video: {
                    maxWidth: 'unset !important',
                    width: 'unset !important'
                  },
                  '&:hover video': {
                    transition: 'all 0.5s ease',
                    transform: 'scale(1.1)',
                    filter: 'brightness(50%)'
                  }
                }}
                overflowX='hidden'
              >
                {children}
                <LinkOverlay
                  to={`/${job.title.toLowerCase()}`}
                  as={Link}
                  w='100%'
                  h='100%'
                  display='flex'
                  position='absolute'
                  alignItems='center'
                  justifyContent='center'
                  flexDirection='column'
                  zIndex='15'
                  _hover={{ transform: 'scale(1.2)' }}
                  sx={{
                    '&': { transition: 'all 0.5s ease' }
                  }}
                >
                  <Heading
                    fontSize={{
                      base: '9vw',
                      sm: '5xl',
                      md: '7xl',
                      lg: '8xl',
                      xl: '9xl',
                      '6xl': '10vw'
                    }}
                    fontStyle='italic'
                    textAlign='center'
                    lineHeight='95%'
                    whiteSpace='nowrap'
                    zIndex='16'
                  >
                    {title}
                  </Heading>
                  {subtitle && (
                    <Text
                      fontSize={{
                        base: '2.65vw',
                        md: '2xl',
                        lg: '3xl',
                        xl: '4xl',
                        '6xl': '2.65vw'
                      }}
                      fontStyle='italic'
                      fontWeight={300}
                      textAlign='center'
                      textTransform='uppercase'
                      lineHeight='80%'
                      zIndex='16'
                    >
                      {subtitle}
                    </Text>
                  )}
                </LinkOverlay>
              </Flex>
            </AspectRatio>
          )
        }}
      />
    </LinkBox>
  )
}

export default JobSection
