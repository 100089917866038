import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  fonts: {
    heading: 'Rubik',
    body: 'Rubik'
  },
  colors: {
    gray: {
      '100': '#E6E6E6', // Cinza Claro
      '600': '#4D4D4D', // Cinza Escuro
      '700': '#3E3E3E' // Cinza Mais Escuro
    },
    green: {
      '300': '#99FF33' // Verde
    }
  },
  styles: {
    global: {
      body: {
        bg: 'gray.700', //background
        color: 'gray.100',
        minHeight: '100vh'
      }
    }
  },
  breakpoints: {
    sm: '30em', //480px
    md: '48em', //768px
    lg: '62em', //992px
    xl: '80em', //1280px
    '2xl': '96em', //1536px
    '3xl': '114rem', //1824px
    '4xl': '132rem', //2112px
    '5xl': '144rem', //2304px
    '6xl': '160rem' //2560px
  }
})
