import { Flex, Image, SimpleGrid } from '@chakra-ui/react'
import React from 'react'

import { Job } from '../../types/Job'

import VideoPlayer from '../VideoPlayer'

interface JobContentProps {
  job: Job
}

const JobContent: React.FC<JobContentProps> = ({ job }: JobContentProps) => {
  const { resources } = job

  if (!resources || resources.length === 0) return null

  return (
    <Flex
      width='100%'
      height='100%'
      align={'center'}
      justify='center'
      direction={'column'}
      backgroundColor='gray.700'
    >
      {resources.map((resource, index) => {
        if (resource.video?.url)
          return (
            <VideoPlayer
              url={resource.video.url}
              thumbnailUrl={resource.video.thumbnail}
              title={resource.video.title}
              key={resource.video.url}
            />
          )

        if (resource.image && resource.image.url)
          return (
            <SimpleGrid
              key={index}
              w='100%'
              columns={{
                base: resource.image.url.length % 2 === 0 ? resource.image.url.length / 2 : 1,
                sm: resource.image.url.length % 2 === 0 ? resource.image.url.length / 2 : 1,
                md: resource.image.url.length,
                lg: resource.image.url.length
              }}
            >
              {resource.image.url.map((url, index) => {
                return (
                  <Image
                    src={url}
                    alt={url}
                    width='100%'
                    loading='lazy'
                    overflow='hidden'
                    key={`${index}-${url}`}
                  ></Image>
                )
              })}
            </SimpleGrid>
          )
        return null
      })}
    </Flex>
  )
}

export default JobContent
