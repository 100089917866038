import { Flex, Text } from '@chakra-ui/react'

interface BioJobsProps {
  experiences?: string[]
}

const BioJobs = ({
  experiences = ['FREELANCER', 'BIO2', 'PUBLICIS', 'VMLY&R', 'LEO BURNETT', 'TEAM ONE', '—']
}: BioJobsProps) => {
  return (
    <Flex
      w='100%'
      h='fit-content'
      px='7'
      paddingBottom={{ base: '3', md: '4' }}
      fontSize={{ base: 'md', '6xl': '3xl' }}
      lineHeight='138%'
      fontStyle='italic'
      fontWeight={700}
      direction='column'
      borderBottom='1px'
      borderColor='gray.700'
    >
      {experiences.map((experience, index) => (
        <Text casing='uppercase' key={index}>
          {experience}
        </Text>
      ))}
    </Flex>
  )
}

export default BioJobs
