import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

interface MenuDrawerProviderProps {
  children: ReactNode
}

type ContentDrawer = 'bio' | 'jobs'

interface MenuDrawerContextData extends UseDisclosureReturn {
  contentDrawer: string
  onOpenBioDrawer: () => void
  onOpenJobsDrawer: () => void
  isJobsDrawerOpen: boolean
  isBioDrawerOpen: boolean
}

const MenuDrawerContext = createContext({} as MenuDrawerContextData)

export function MenuDrawerProvider({ children }: MenuDrawerProviderProps) {
  const disclosure = useDisclosure()
  const location = useLocation()

  const [contentDrawer, setContentDrawer] = useState<ContentDrawer>('bio')

  const onOpenBioDrawer = () => {
    setContentDrawer('bio')
    disclosure.onOpen()
  }

  const onOpenJobsDrawer = () => {
    setContentDrawer('jobs')
    disclosure.onOpen()
  }

  useEffect(() => {
    disclosure.onClose()
  }, [location])

  return (
    <MenuDrawerContext.Provider
      value={{
        ...disclosure,
        onOpenBioDrawer,
        onOpenJobsDrawer,
        contentDrawer,
        isJobsDrawerOpen: contentDrawer === 'jobs',
        isBioDrawerOpen: contentDrawer === 'bio'
      }}
    >
      {children}
    </MenuDrawerContext.Provider>
  )
}

export const useMenuDrawer = () => useContext(MenuDrawerContext)
