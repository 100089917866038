import { chakra, Flex, shouldForwardProp, Slide } from '@chakra-ui/react'
import { isValidMotionProp, motion, useScroll } from 'framer-motion'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useMenuDrawer } from '../../contexts/MenuDrawerContext'

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop)
})

interface StickyHeaderProps {
  children: ReactNode
}

const StickyHeader: React.FC<StickyHeaderProps> = ({ children }: StickyHeaderProps) => {
  const { scrollY } = useScroll()

  const [previousY, setPreviousY] = useState<number>(0)
  const [isScrollUp, setIsScrollUp] = useState(false)
  const { isOpen } = useMenuDrawer()

  const ref = useRef() as React.MutableRefObject<HTMLDivElement>

  useEffect(() => {
    return scrollY.onChange((latest) => {
      if (previousY !== null && previousY !== undefined && ref.current) {
        if (latest < previousY && ref.current.scrollHeight < previousY) {
          setIsScrollUp(true)
        } else {
          setIsScrollUp(false)
        }

        setPreviousY(latest)
      }
    })
  }, [scrollY, previousY])

  return (
    <Flex ref={ref} w='100%' h='14' backgroundColor='green.300' zIndex={17}>
      {children}
      <Slide direction='top' in={isScrollUp} style={{ zIndex: 10 }}>
        <ChakraBox w='100%'>{children}</ChakraBox>
      </Slide>
    </Flex>
  )
}

export default StickyHeader
