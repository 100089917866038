import { Flex, Icon, IconButton } from '@chakra-ui/react'
import React from 'react'

import { FiGrid, FiChevronRight, FiChevronLeft } from 'react-icons/fi'

interface JobFooterProps {
  onLeftClick?: () => void
  onCenterClick?: () => void
  onRigthClick?: () => void
}

const JobFooter: React.FC<JobFooterProps> = ({
  onLeftClick,
  onCenterClick,
  onRigthClick
}: JobFooterProps) => {
  return (
    <Flex bg='green.300' w='100%' h='14' justify='center' alignItems='center' marginTop='auto'>
      <Flex direction='row' gap='5' w='fit-content' h='100%' justify='center' alignItems='center'>
        <IconButton
          icon={<Icon as={FiChevronLeft} />}
          fontSize='32'
          variant='unstyled'
          aria-label='Go to previous job page'
          color='gray.700'
          onClick={onLeftClick}
        />
        <IconButton
          icon={<Icon as={FiGrid} />}
          fontSize='32'
          variant='unstyled'
          aria-label='Go to home page'
          color='gray.700'
          onClick={onCenterClick}
        />
        <IconButton
          icon={<Icon as={FiChevronRight} />}
          fontSize='32'
          variant='unstyled'
          aria-label='Go to next job page'
          color='gray.700'
          onClick={onRigthClick}
        />
      </Flex>
    </Flex>
  )
}

export default JobFooter
