import { Flex, Heading, VStack } from '@chakra-ui/react'
import { useResume } from '../../contexts/ResumeContext'

import BioContacts from './BioContacts'
import BioJobs from './BioJobs'
import BioResume from './BioResume'

const Bio = () => {
  const { email, instagram, linkedin, about, curriculum, experiences } = useResume()

  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      direction='column'
      px='4'
      justify='center'
      width='100%'
      minH='100%'
      color='gray.700'
    >
      <VStack
        maxW={{
          base: '20.25rem',
          md: '25.6875rem',
          '6xl': '48rem'
        }}
        spacing={{
          base: '3',
          md: '4'
        }}
        alignItems='center'
        justifyContent='center'
      >
        <Heading
          fontSize={{
            base: '5xl',
            md: '5xl',
            lg: '6xl',
            '6xl': '8xl'
          }}
          borderTop='1px'
          borderBottom='1px'
          borderColor='gray.700'
          lineHeight={{ base: '168.5%', md: '168.5%' }}
          w='100%'
          h='fit-content'
          textAlign='center'
          fontStyle='italic'
          letterSpacing='-0.05em'
        >
          EXPERIENCE
        </Heading>
        <BioJobs experiences={experiences} />
        <BioContacts
          instagram={instagram}
          linkedin={linkedin}
          curriculum={curriculum}
          email={email}
        />
        <BioResume resume={about} />
      </VStack>
    </Flex>
  )
}

export default Bio
